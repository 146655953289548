const Logo = () => {
    return (
        <div className="row g-0 justify-content-center">
            <div className="col-8 text-center">
                <img className="w-100" src="/logo.png" alt="" />
            </div>
        </div>
    )
}

export default Logo