import { Spinner } from 'react-bootstrap'

const Loader = () => {
    return (
        <div className="text-center mb-4 d-flex align-items-center">
            <Spinner animation="border" variant="light" className="me-2" />
            <div className="text-white" style={{fontSize: "16px"}}>Loading...</div>
        </div>
    )
}

export default Loader